import * as React from 'react';
import {Typography, Box} from '@mui/material';

// this function will be used for drawing the bullets as well 
// as Header and footer lines based on the parameter passed
function PMALineBullet(props) {

    const commonStyles = {
        bgcolor: 'error.main',
        borderColor: 'error.main',
      };

    return(
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
               
                    <Box sx={{ ...commonStyles, border: 1, height: '0.25rem',width: '15rem', mt: 0.3}} />  {/* adding a line component */}
                    <Box sx={{ ...commonStyles, borderRadius: '50%', height: '0.75rem',width: '0.75rem', }} /> {/* appending a circle to above line component */}
                
                <Typography variant="h5" sx={{ml: 1, color: 'primary.dark'}}> 
                        <b>{props.secTitle} </b>
                </Typography>
            </Box>
            
        </div>
    );
}

export default PMALineBullet;
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import HistoryAccordian from "../components/HistoryAccordian";
import SoHAccordian from "../components/SoHAccordian";
import ABAccordian from "../components/ABAccordian";
import TrustTAccordian from "../components/TrustTAccordian";
import AccreAccordian from "../components/AccreAccordian";
import CoEAccordian from "../components/CoEAccordian";
import MoUAccordian from "../components/MoUAccordian";
import CoExAccordian from "../components/CoExAccordian";
import PMAPageFooter from "../components/PMAPageFooter";
import PMALineBullet from "../components/PMALineBullet";

function AboutUsPg() {
    const secTitle="";
    return(

        <div>
            <br/>
            <PMALineBullet secTitle={secTitle + 'About Us'}/>
            <br/>
            <Box sx={{mr: 5, ml:5}}>
                <Typography variant="h6">
                    The Pune Management Association (PMA) is an organization that is dedicated to promoting excellence in the field
                    of management. </Typography>
                <br/>

                <HistoryAccordian />
                <br/>
                <SoHAccordian/>
                <br/>
                <ABAccordian/>
                <br/>
                <TrustTAccordian/>
                <br/>
                <AccreAccordian/>
                <br/>
                <CoEAccordian />
                <br/>
                <MoUAccordian />
                <br/>
                <CoExAccordian />
                <br/>
           </Box>

           <PMAPageFooter />
        </div>
    );

}

export default AboutUsPg;
import {Box, Button, Typography} from '@mui/material'
import { Link } from 'react-router-dom';

function PMAMoreButton(props) {

    return(
        <div>
            <Box
                m={1} //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
            >
                
                <Link variant='string' sx={{textTransform: 'capitalize'}} to={props.destPg}>
                    <Typography variant="h6" sx={{color: 'primary.main'}}> {props.moreMsgStr} </Typography>
                </Link>
            </Box>

        </div>
    );
}

export default PMAMoreButton;
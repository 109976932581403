// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_DB_API_KEY,
  authDomain: process.env.REACT_APP_DB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID
};

// console.log("Api key is : ", firebaseConfig.apiKey);
// console.log("proj id", firebaseConfig.projectId)
// console.log("storeage", firebaseConfig.storageBucket)

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const storage = getStorage(app);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const appAdminEmailID1 = process.env.REACT_APP_ADMIN1;
const appAdminEmailID2 = process.env.REACT_APP_ADMIN2;
const appAdminEmailID3 = process.env.REACT_APP_ADMIN3;

// console.log("Admin ids from env file is:",appAdminEmailID1, 
//               appAdminEmailID2, appAdminEmailID3);

export { firebaseConfig, app, db, storage, auth, provider,
  appAdminEmailID1, appAdminEmailID2, appAdminEmailID3 };

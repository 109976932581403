import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Button, FormControlLabel, FormGroup, Stack, Switch, TextField, Typography } from "@mui/material";
import { CheckBox, Label } from "@mui/icons-material";
import { collection, getDocs, getDoc, addDoc, add, getFirestore } from 'firebase/firestore';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { db } from "../firebase.config"
import LogoJpeg from "../resources/PMA-logo.jpg"
import FileUpload from "./FileUpload";
import { useNavigate } from "react-router-dom";


function PMAAddEventCard() {

    const [dtVal, setDtVal] = useState(null);
    const [tmVal, setTmVal] = useState(null);

    // const [dtVal, setDtVal] = useState(Date.now());
    // const [tmVal, setTmVal] = useState(Date.now());

    const [eventCard, setEventCard] = useState(
        {
            eventID: '',
            eventTitle: '',
            eventSpeakrNm: '',
            eventDt: dtVal,
            eventTm: tmVal,
            eventAdditionalDtls: '',
            eventActive: true,
            eventFlyerImgURL: '',
            eventTkAway: '',
            eventForWhom: '',   // this is same as expected participants text field
            displayEventIDField: 'none',    // this makes the eventid field invisible. If value is changed to anything other than 'none' then the dield reappears on the screen
        }
    )

    //let displayEventIDField = false; // random event id generated based on timestamp in milliseconds
    const [formSubmitted, setFormSubmitted] = useState(false)

    // file upload to firebase storage location
    //const [fileUrl, setFileUrl] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    const fileStoredAt = (fileUrl) => {
        setFileUrl(fileUrl);
        //console.log("file storage url received is >>> ", fileUrl);
        //console.log(fileUrl);
    };

    const navigateTo = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault()

        eventCard.eventID = Date.now();
        //eventCard.eventDtAndTime = dtTmVal.format();
        eventCard.eventDt= dtVal.get('D') + '-' + (dtVal.get('M') + 1) + '-' + dtVal.get('y');
        //eventCard.eventTm= tmVal.format();
        eventCard.eventTm= tmVal.get('h') + ':' + tmVal.get('m')

        // let dateVal = Date(eventCard.eventDtAndTime);
       // console.log("Date is : ", eventCard.eventDt);

        // let timeVal = eventCard.eventDtAndTime.gethours();
        //console.log("Time is : ", eventCard.eventTm);

        eventCard.eventFlyerImgURL = fileUrl;
        //console.log("Event flyer updated with >>> ", eventCard.eventTm);

        setEventCard({
            ...eventCard,
            //eventID: e.target.value,
            eventTitle: e.target.value,
            eventSpeakrNm: e.target.value,
            //eventDtAndTime: dtTmVal,
            eventAdditionalDtls: e.target.value,
            eventFlyerImgURL: e.target.value,
            eventActive: e.target.value,
            eventTkAway: e.target.value,
            eventForWhom: e.target.value,
            displayEventIDField: 'show',        // replacing value of 'none' to show event id field
        });

        let tempFlyerImageURL = eventCard.eventFlyerImgURL;
        //console.log("temp image url is: ", tempFlyerImageURL);
        //recaptchaRef.current.execute(); // v3 verstion of invisible recaptcha

        storeInDB(eventCard);

        //db.collection('pmaEvntData').getDocs();
        //    const res = db.collection('pmaEvntData').add({
        //     eventID: 98765,
        //     eventTitle: 'New title',
        //     eventSpeakrNm: 'xyz',
        //     eventDtAndTime: '23-10-23 7pm',
        //     eventAdditionalDtls:'no other details',

        //    });


        setFormSubmitted(true);
        //console.log(formSubmitted)

    }

    async function storeInDB(eventCard) {
        //console.log("event card to store: ", eventCard);
        //Add a new document with a generated id.
        const docRef = await addDoc(collection(db, "pmaEvntData"), eventCard);
        //console.log("Document written with ID: ", docRef.id);
    }

    //   Function to update the eventFlyerImg URL so that preview does not get refreshed after submitting the form
    const handleImageUrlChange = (newImageUrl) => {
        setEventCard({
            ...eventCard,
            eventFlyerImgURL: newImageUrl,
        });
    };

    useEffect(()=>{
        sessionStorage.getItem("uemail")
        ?
            <></>
        :
            navigateTo('/');
            
    }, []);

    return (

        <>
            <Typography variant='h4' align='center' sx={{ mt: 5, color: 'blue' }}> <b>Add New Event</b> </Typography>
            <form onSubmit={handleSubmit}>
                <Grid2 container spacing={2}>

                    <Grid2 xs={6}>


                        <Stack direction='column' spacing={2} sx={{ m: 5 }}>

                            <TextField id="event-id" 
                                label= {eventCard.eventID}
                                variant="filled" disabled={true}
                                sx={{ display: eventCard.displayEventIDField }}
                            />

                            <TextField
                                id="event-title" label="Event Title"
                                //value={eventCard.eventTitle}
                                variant="outlined"
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventTitle: ev.target.value
                                    })
                                }}
                            />

                            <TextField
                                id="speaker-nm" label="Speaker Name"
                                variant="outlined"
                                //value={eventCard.eventHostSpeaker}
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventSpeakrNm: ev.target.value
                                    })
                                }} />

                            {/* <TextField
                                id="dt-n-time" label="Date & Time (dd-mon-yy hh:mm)"
                                variant="outlined"
                                //value={eventCard.eventDtAndTime}
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventDtAndTime: ev.target.value
                                    })
                                }}
                            /> */}

                            {/* date and time picker components */}

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
      
                                <DatePicker id="dt-pickr"
                                value={dtVal}
                                onChange={setDtVal}
                                defaultValue={dayjs(new Date())}
                                />

                                <TimePicker id='tm-pikr'
                                    value={tmVal}
                                    onChange={setTmVal} 
                                    //referenceDate={dayjs('2022-04-17T15:30')}        
                                    referenceDate={dayjs(new Date())}        
                                />
                            </LocalizationProvider>



                            <TextField
                                id="tk-away" label="Key Take Away"
                                variant="outlined" multiline={true} rows={3}
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventTkAway: ev.target.value
                                    })
                                }}
                            />


                            <TextField
                                id="for-whom" label="Expected Participants"
                                variant="outlined" multiline={true} rows={3}
                                // value={eventCard.eventAdditionalDtls}
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventForWhom: ev.target.value
                                    })
                                }}
                            />

                            <TextField
                                id="addn-dtls" label="Any Additional Details"
                                variant="outlined" multiline={true} rows={3}
                                // value={eventCard.eventAdditionalDtls}
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventAdditionalDtls: ev.target.value
                                    })
                                }}
                            />



                            <TextField
                                id="flyer-url" label={fileUrl}
                                ariant="outlined"
                                disabled={true}
                                sx={{ display: 'none' }}
                                //value={eventCard.eventFlyerImgURL}
                                // onChange={ev => {
                                //     setEventCard({
                                //         ...eventCard,
                                //         eventFlyerImgURL: ev.target.value
                                //     })
                                // }}
                            />

                            {/* 
                            <CheckBox defaultChecked
                                inputProps={{ 'aria-label': 'controlled' }}
                                id="activeStatusChkBx" 
                                label="IsActive?"
                                //value={eventCard.eventActive}
                                onChange={ev => {
                                    setEventCard({
                                        ...eventCard,
                                        eventActive: ev.target.checked
                                    })
                                }}
                            /> */}

                            <FormGroup>
                                <FormControlLabel control={

                                    <Switch defaultChecked
                                        onChange={ev => {
                                            setEventCard({
                                                ...eventCard,
                                                eventActive: ev.target.checked
                                            })
                                        }}
                                    />
                                }
                                labelPlacement="start"
                                label="IsActive?" />
                            </FormGroup>


                            <Button id="frmSub-button" type="submit"
                                variant="contained" disabled={formSubmitted}> Submit</Button>

                        </Stack>


                    </Grid2>

                    <Grid2 xs={6} lg={6}>

                        {/* <Box id="flyerImg" component={'img'}
                            sx={{
                                height: 500,
                                width: 500,
                                mt: 5,
                                border: 1
                            }}
                            src={eventCard.eventFlyerImgURL}
                            alt="Event Flyer" /> */}
                        
                        <Box border={1} margin={5}>
                            <FileUpload fileStoredAt={fileStoredAt} />
                        </Box>

                    </Grid2>
                </Grid2>
            </form>


            {/* <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="Your client site key"
                onChange={onChange}
                /> */}


        </>

    );
}

export default PMAAddEventCard;
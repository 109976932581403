import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { appAdminEmailID1, appAdminEmailID2, appAdminEmailID3 } from "../firebase.config";

export default function PMAUserWelcomePg({userID}){

    // const navigateTo = useNavigate();
    
    // return(
    //     <>
    //         <Box m="auto" border={1}>
            
    //             <h3> You have successfully logged in !! Please proceed with next action.</h3>
    //             <br/>
    //             <Button variant="contained" onClick={()=>{navigateTo('../addnewEvent', {replace:true,})}}> Add New Event</Button>

    //         </Box>

    //     </>
    // );


    const [isAdmin, setIsAdmin] = useState(false);
    const navigateTo = useNavigate();

    useEffect(()=>{
        //console.log("User id received on welcome pg is >>>>", {userID});
        //console.log("Admin ids from ENV on welcome pg is >>>>", appAdminEmailID1, appAdminEmailID2, appAdminEmailID3 );
        
   
            if ((userID && userID === appAdminEmailID1)
                    || (userID && userID === appAdminEmailID2)
                    || (userID && userID === appAdminEmailID3)){
                setIsAdmin(true);
                //console.log("Setting admin value >>>", isAdmin);
            }
    
    },[isAdmin]);
    

   
    
    
    return(
        <>
            <Box m="auto">
            
                <h3 style={{ color: 'blue' }}> Hello {userID} !! </h3> 
                <h3> Please proceed with next action.</h3>
                <br/>
                
                    {/* (userID && userID === process.env.REACT_APP_ADMIN1) */}
                 {    
                    isAdmin
                     ?
                        <Button variant="contained" 
                        onClick={()=>{navigateTo('../addnewEvent', 
                        {replace:true,})}}> Add New Event</Button>
                    :
                        <Button variant="contained" 
                        onClick={()=>{navigateTo('../', 
                        {replace:true,})}}> Go to Home</Button>
                 }

                    {/* <Button variant="contained" 
                        onClick={()=>{
                            isAdmin 
                            ? 
                                //navigateTo('../admin', {replace:true})
                                navigateTo('admin')
                            :
                                navigateTo('userdata')}}
                        > Proceed</Button> */}

                    


            </Box>

        </>
    );

}
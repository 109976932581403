import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function TnCPg(){

    return(

        <div>

            <Box sx={{maxWidth: 1000, border:1, m:5}}>

                <Typography>

                <p>Privacy Policy</p>
                <p>Last updated on Nov 25th 2023</p>
                <p>This privacy policy sets out how PUNE MANAGEMENT ASSOCIATI031ON uses and protects any information that you give PUNE MANAGEMENT ASSOCIATI031ON when you use this website.
PUNE MANAGEMENT ASSOCIATI031ON is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
PUNE MANAGEMENT ASSOCIATI031ON may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.
We may collect the following information:
•	Name and job title
•	Contact information including email address
•	Demographic information such as postcode, preferences and interests
•	Other information relevant to customer surveys and/or offers
What we do with the information we gather
We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
•	Internal record keeping.
•	We may use the information to improve our products and services.
•	We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.
•	From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.
We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.
How we use cookies
A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
Controlling your personal information
You may choose to restrict the collection or use of your personal information in the following ways:
•	whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes
•	if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at Secretary@PuneManagementAssociation.org
We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
{/* Disclaimer: The above content is created at PUNE MANAGEMENT ASSOCIATI031ON's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it. */}
</p>
<p>Terms & Conditions
Last updated on Nov 25th 2023
The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.
Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern PUNE MANAGEMENT ASSOCIATI031ON''s relationship with you in relation to this website.
The term 'PUNE MANAGEMENT ASSOCIATI031ON' or 'us' or 'we' refers to the owner of the website whose registered/operational office is 1332, JM Road, Shivajinagar, Pune 411005 Pune MAHARASHTRA 411005. The term 'you' refers to the user or viewer of our website.
The use of this website is subject to the following terms of use:
•	The content of the pages of this website is for your general information and use only. It is subject to change without notice.
•	Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
•	Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
•	This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
•	All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
•	Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
•	From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information.
•	You may not create a link to this website from another website or document without PUNE MANAGEMENT ASSOCIATI031ON’s prior written consent.
•	Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.
We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time
</p>
{/* <p>Disclaimer: The above content is created at PUNE MANAGEMENT ASSOCIATI031ON's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.</p> */}
<p>Cancellation & Refund Policy
Last updated on Nov 25th 2023
No cancellations & Refunds are entertained
{/* Disclaimer: The above content is created at PUNE MANAGEMENT ASSOCIATI031ON's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it. */}
</p>





                </Typography>


            </Box>

        </div>

    );

}

import React from 'react';
import Link from '@mui/material/Link';
import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
//import Layout from "./pages/Layout";
import PMANavBar from './components/PMANavBar';
import PMAHomePage from './PMA_Pages/PMAHomePage';
import AboutUsPg from './PMA_Pages/AboutUsPg';
import MembershipPg from './PMA_Pages/MembershipPg';
import ContactUsPg from './PMA_Pages/ContactUsPg';
import UpcomingEventsPg from './PMA_Pages/UpcomingEventsPg';
import MediaCenterPg from './PMA_Pages/MediaCenterPg';
import BlogPg from './PMA_Pages/BlogPg';
import PMAAddEventCard from './components/PMAAddEventCard';
import TnCPg from './PMA_Pages/TnCPg';
import TestPg from './PMA_Pages/TestPg';
import PMALoginPg from './PMA_Pages/PMALoginPg';
import Test1ImgUpload from './components/Test1ImgUpload';
import KISPg from './PMA_Pages/KISPg';
import FoundationDayPg from './PMA_Pages/FoundationDayPg';

function App() {

  return (
    <>
    <PMANavBar />
    <Routes>
        <Route path="/" element={<PMAHomePage />}/>
        <Route path="upcomingEvents" element={<UpcomingEventsPg />} />
        <Route path="aboutus" element={<AboutUsPg />} />
        <Route path="membership" element={<MembershipPg />} />
        <Route path="mediaCenter" element={<MediaCenterPg />} />
        <Route path="blog" element={<BlogPg />} />
        <Route path="contactus" element={<ContactUsPg />} />
        <Route path="admin" element={< PMALoginPg/>} />
        <Route path="TnC" element={< TnCPg/>} />
        <Route path="FD" element={< FoundationDayPg/>} />
        {/* <Route path="KIS" element={< KISPg/>} /> */}
        <Route path="TestPg" element={< Test1ImgUpload/>} />
        <Route path="addnewEvent" element={< PMAAddEventCard/>} />
        {/* <Route path="Attendance" element={<PMAAddEventCard/>}/> */}
    
    </Routes>
    </>
  );
}

export default App

import { createTheme, Box, Button, Divider, Typography, Stack } from "@mui/material";
import PMANavBar from "../components/PMANavBar";
import { blue, grey } from '@mui/material/colors';
import { ThemeProvider } from "@emotion/react";
import PMACarousal from "../components/PMACarousal";
import PMAMoreButton from "../components/PMAmoreButton";
import PMAImpactNumbers from "../components/PMAImpactNumbers";
import PMALineBullet from "../components/PMALineBullet";
import PMAUpcomingEventsStepper from "../components/PMAUpcomingEventsStepper";
import PMAPageFooter from "../components/PMAPageFooter";
import CustomStepper from "../components/CustomStepper";



function PMAHomePage() {

    const PMATheme = createTheme( {

        palette:{
            primary:{
               main: '#1976d2',
               light: '#42a5f5',
               dark: '#1565c0',
               contrastText: '#fff',
            },

            pmaMenu:{
                main: grey[900], // black colour
                dark: grey[50] // white colour
            },  
            
            pmaDivider:{
                primary:{
                main: grey[900], // black colour
            }

            },
        }
    });


    const moreMsgStr = 'More on ';
    const secTitle = "";

    return(
        <div>
            <ThemeProvider theme={PMATheme}>
                {/* <PMANavBar />            */}
                <Box sx={{mr: 5, ml:5, alignItems:'center', justifyContent:'center'}}>
                    <br/>
                    <PMALineBullet secTitle={secTitle + 'Home'}/>
                    <br/>
                    <PMACarousal />    
                    <PMAMoreButton moreMsgStr = {moreMsgStr + 'Media center >>'} destPg="/mediacenter"/>
                    <p/>
                    <PMALineBullet secTitle={secTitle + 'Welcome to PMA'}/>
                    {/* <Typography variant="h4" sx={{color: 'primary.dark'}}> 
                        Welcome To PMA
                    </Typography>  */}
                    <Typography variant="h6" sx={{color: 'primary.blackC'}}> 
                    <p/>
                    <div>
                        Pune Management Association (PMA) is a body of Knowledge, a Centre of Excellence in the
                        domain of Management that empowers the individuals and Enterprises to excel in their
                        endeavour.
                    </div>
                    </Typography>

                    <PMAMoreButton moreMsgStr = {moreMsgStr + 'About Us >>'} destPg="/aboutus"/>

                    {/* <Divider> </Divider> */}

                    <p/>
                    <PMALineBullet secTitle={secTitle + 'Our Impact At Glance'}/>
                    
                    <br/>
                    <p/>
                    <Stack
                    
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <PMAImpactNumbers title="Years of Service" iNumber="48"/>
                        <PMAImpactNumbers title="Total Memberships" iNumber="1200+"/>
                        <PMAImpactNumbers title="Total Events" iNumber="480+"/>
                        <PMAImpactNumbers title="Total MoUs" iNumber="60+"/>
                        <PMAImpactNumbers title="Total CoEs" iNumber="19"/>
                    </Stack>
                    <br/>
                    <p/>
                    <PMALineBullet secTitle={secTitle + 'Upcoming Events'}/>
                    <p/>
                    <br />
                    {/* <Box sx={{alignItems:'center', justifyContent:'center', maxWidth:'70%'}}>
                    <PMAUpcomingEventsStepper />
                    </Box> */}
                    {/* <Box sx={{alignItems:'center', justifyContent:'center', maxWidth:'70%'}}> */}
                        <CustomStepper/>
                    {/* </Box> */}
                    <br />

                </Box> 
                {/* Above Box component aligns the contents of complete page */}

                <PMAPageFooter />
                    <br />
            </ThemeProvider>
                
        </div>

    );
}

export default PMAHomePage;
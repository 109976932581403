import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, List, ListItem } from '@mui/material';

function AccreAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> Accreditations </b></Typography>

        </AccordionSummary>
        <AccordionDetails>
            <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>Pune Management Association(PMA), independent Trust and Public Organisation (Trust Registration no <b>F1011</b> dated 27th May 1977). 
              </p>        
              <b>Other Registrations:- </b>
            <List>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}>PMA has 80G exemption 
                from Income Tax vide registration no AATP1088PPF20214.</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}>PAN No AAATP1088P.</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}>NITI Ayog (Darpan) No MH/2018/0188345 </ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}>MSME Ministry (GoI) vide Udyam Registration no MH-26-0062691.</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}>ISO 9001:2015 accreditation</ListItem>
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default AccreAccordian;
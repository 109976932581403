import * as React from 'react';
//import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
//import WhatsAppIcon from "../resources/whatsapp-svgrepo-com.svg" ;
import {ReactComponent as WhatsAppReactLogo} from "../resources/whatsapp-svgrepo-com.svg";
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LinkedIn } from '@mui/icons-material';



export default function PMAPageFooter() {

    //const footNavi = useNavigate(); // used for handling the routes

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>

        {/* draw a header/footer line component */}
        <Box sx={{ bgcolor: 'primary.main', 
            flexGrow: 1, border: 1, 
            height: '0.1rem', width: 'auto'}} />
        <br />
        
          <Grid container minHeight={160}
          
          spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center">
              <div>
                    <Box><b>About Us</b></Box>
                    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                        <ol style={{ listStyleType: 'square', 
                                    color: 'primary.main' }}>
                            <li><Link to='/aboutus'> History </Link></li>
                            <li><Link to='/aboutus'> Scroll of Honour </Link></li>
                            {/* <li><Link to='/aboutus'>Governance</Link></li> */}
                            <li><Link to='/aboutus'>MoUs</Link></li>
                            <li><Link to='/aboutus'>CoEs</Link></li>
                        </ol>
                    </Box>
                </div>
            </Grid>
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
            <div>
                    <Box><b>Membership</b></Box>
                    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                        <ol style={{ listStyleType: 'square', 
                                    color: 'primary.main' }}>
                            <li><Link to='/membership'>Benefits</Link></li>
                            <li><Link to='/membership'>Professional</Link></li>
                            <li><Link to='/membership'>Students</Link></li>
                        </ol>
                    </Box>
                </div>
            </Grid>
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
                <div>
                        <Box><b>Contact Us</b></Box>
                        <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                            <ol style={{ listStyleType: 'square', 
                                        color: 'primary.main' }}>
                                <li><Link to='/contactus'>Enquiry</Link></li>
                                <li><Link to='/contactus'>Address</Link></li>
                            </ol>
                        </Box>
                </div>
            </Grid>
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
                <div>
                         {/* open the TnC page in browser window using target option*/}
                        {/* <Box><b><Link to='/TnC' target='_blank'>   Terms and Privacy Policy</Link></b></Box> */}
                        <Box><b><Link to='/TnC'>Terms & Privacy Policy</Link></b></Box>
                        
                </div>
            </Grid>
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
                <div>
                    <Box><b>Social Media:</b></Box>
                    <Stack direction="row" >
                        <IconButton href="https://www.linkedin.com/company/pune-management-association/">
                            <LinkedIn color={'primary'} fontSize={'large'}/>
                        </IconButton>

                        <IconButton href="https://www.youtube.com/@PuneManagementAssociation">
                            <YouTubeIcon color={'error'} fontSize={'large'} />
                        </IconButton>
                    </Stack>
                        
                    <Stack direction="row" >

                        <IconButton href="https://www.facebook.com/groups/PuneManagement">
                            <FacebookIcon color={'primary'} fontSize={'large'}/>
                        </IconButton>

                        <IconButton href="https://wa.me/9422318263">
                            <WhatsAppReactLogo/>
                        </IconButton>

                        

                    </Stack>
                </div>
            </Grid>
          </Grid>


          {/* draw a header/footer line component */}
        <Box sx={{ bgcolor: 'primary.main', 
            flexGrow: 1, border: 1, 
            height: '0.1rem', width: 'auto'}} />
        <br />
        
        </Box>

        <div>
            <Box display="flex" justifyContent="flex-end">
                <div>
                    <Typography fontSize={10}
                        sx={{mr:3}}>Copyright @2023-25 Pune Management Association Pune</Typography>
                </div>
            </Box>
            <Box display="flex" justifyContent="flex-end">
            <div>
                <Typography fontSize={10}
                    sx={{mr:3}}>Powered by <a href="https://www.omnevu.com" target="_blank" 
                    rel="noopener noreferrer">OmneVu.com</a></Typography>
            </div>
            </Box>
            
        </div>
        </>
      );
}
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@mui/material";
import PMALineBullet from "../components/PMALineBullet";
import Test1Db from "../components/Test1Db";
import PMAPageFooter from "../components/PMAPageFooter";
import BlogBox from "../components/BlogBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/system";

function BlogPg(){
    const secTitle="";

    const blogPost = [
        { 
            title:"1. The Power of Women Entrepreneurship Culture",
            blogLnk:"https://drive.google.com/file/d/1zCqZjPtotLoPAIr4ESIuxzev-NQtn2Mo",
            //type: "A",            // A-Article, N-News, B-Blog Post. Default value will be Article.
        },

        { 
            title:"1. The Power of Women Ent Culture",
            blogLnk:"https://drive.google.com/file/d/1zCqZjPtotLoPAIr4ESIuxzev-NQtn2Mo",
            //type: "A",            // A-Article, N-News, B-Blog Post. Default value will be Article.
        },

        { 
            title:"1. The Power of Women Entrepreneurship Culture",
            blogLnk:"https://drive.google.com/file/d/1zCqZjPtotLoPAIr4ESIuxzev-NQtn2Mo",
            //type: "A",            // A-Article, N-News, B-Blog Post. Default value will be Article.
        },

        { 
            title:"1. The Power of Women Entrepreneurship Culture",
            blogLnk:"https://drive.google.com/file/d/1zCqZjPtotLoPAIr4ESIuxzev-NQtn2Mo",
            //type: "A",            // A-Article, N-News, B-Blog Post. Default value will be Article.
        },

        { 
            title:"1. The Power of Women Entrepreneurship Culture",
            blogLnk:"https://drive.google.com/file/d/1zCqZjPtotLoPAIr4ESIuxzev-NQtn2Mo",
            //type: "A",            // A-Article, N-News, B-Blog Post. Default value will be Article.
        },

        { 
            title:"1. The Power of Women Entrepreneurship Culture",
            blogLnk:"https://drive.google.com/file/d/1zCqZjPtotLoPAIr4ESIuxzev-NQtn2Mo",
            //type: "A",            // A-Article, N-News, B-Blog Post. Default value will be Article.
        }
    ]

return(
    <div>
        <br/>
        <PMALineBullet secTitle={secTitle + 'Blog and News articles'}/>
        <br/>

        {/* There will be four accordians whch will represent last 4 months of 
        document uploads 
        A new accrodian will be added on the top to represnt latest month
        */}
    {/* // Oct 2023 data */}
    <Accordion sx={{border: 2, borderColor: 'primary.main', m:1}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> October 2023 </b></Typography>
        </AccordionSummary>
        
        <AccordionDetails>
            <Divider/>
            <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
                {
                blogPost.map( (bp) => {
                    
                    return (
                        <Grid2 xs={4}>
                        <BlogBox title={bp.title} blogLnk={bp.blogLnk} />
                        </Grid2>
                        );
                    })
                }

            </Grid2>
            
        </AccordionDetails>
    </Accordion>
    <br/>
    
    {/* // Sep 2023 data */}
    <Accordion sx={{border: 2, borderColor: 'primary.main', m:1}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> September 2023 </b></Typography>
        </AccordionSummary>
        
        <AccordionDetails>
            <Divider/>
            <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
                {
                blogPost.map( (bp) => {
                    
                    return (
                        <Grid2 xs={4}>
                        <BlogBox title={bp.title} blogLnk={bp.blogLnk} />
                        </Grid2>
                        );
                    })
                }

            </Grid2>
            
        </AccordionDetails>
    </Accordion>
    <br/>

    {/* // Aug 2023 data */}
    <Accordion sx={{border: 2, borderColor: 'primary.main', m:1}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> August 2023 </b></Typography>
        </AccordionSummary>
        
        <AccordionDetails>
            <Divider/>
            <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
                {
                blogPost.map( (bp) => {
                    
                    return (
                        <Grid2 xs={4}>
                        <BlogBox title={bp.title} blogLnk={bp.blogLnk} />
                        </Grid2>
                        );
                    })
                }

            </Grid2>
            
        </AccordionDetails>
    </Accordion>
    <br/>


    <PMAPageFooter/>
    </div>
);
}
export default BlogPg;
import { Box, Stack } from "@mui/system";
import GoogleSignInComp from "../components/GoogleSignInComp";
import { Typography } from "@mui/material";

export default function PMALoginPg(){

    sessionStorage.removeItem("uemail");

    return(
        // <>
        //     <Box alignItems='center' justifyContent='center' flexGrow={1}>
        //         <GoogleSignInComp />
        //     </Box>
        // </>

        <>
        <Stack alignItems='center' justifyContent='center' mt={10}>
        {/* <Box alignItems='center' justifyContent='center' flexGrow={1} m={5}> */}

            <Typography variant="h3"> Welcome to PMA Admin Page</Typography>
            <br/>
            <GoogleSignInComp />
        {/* </Box> */}
        </Stack>
        </>
    );
}
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import SoHTableComp from './SoHTableComp';

function SoHAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}><b> Scroll of Honours </b></Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>Many illustrious personalities and industrialists have led the Association and taken it to new heights.
            </p>
            
            <SoHTableComp/>
            
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default SoHAccordian;
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PMAEventCard from "../components/PMAEventCard";
import PMALineBullet from "../components/PMALineBullet";
import PMAPageFooter from "../components/PMAPageFooter";
import eventFlyer1 from "../resources/eventFlyers/eventFlyer1.jpeg";
import eventFlyer2 from "../resources/eventFlyers/eventFlyer2.jpeg";
import eventFlyer3 from "../resources/eventFlyers/eventFlyer3.jpeg";
import eventFlyer4 from "../resources/eventFlyers/eventFlyer4.jpeg";
import eventFlyer5 from "../resources/eventFlyers/eventFlyer5.jpeg";
import eventFlyer6 from "../resources/eventFlyers/eventFlyer6.jpeg";
import { Button } from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase.config"
import { useEffect, useState } from "react";


// const upcomeEvntList = [
//         {
//             evntTitle: "List of events",
//             evntDtAndTime: "Across Dec 2023",
//             evntResourcePer: "PMA",
//             evntTakeAway: "Interactive Session - experience sharing",
//             evntForWhom: "Business persons, students, individuals",
//             evntFlyerLnk: eventFlyer1,
//             //evntFlyerLnk: "https://drive.google.com/uc?export=view&id=1hBPjornE1SMaQhr3s0pqWHNf_iUvjQdr", 
//             // google drive link in above is modified like https://drive....id=<document id>
//             // one can replace same google drive file/image by uploading a new version through
//             // googledirve right click=>file information->manage versions by uploading new file version
//             // this helps in maintaining the same google drive document ID w/o any need to change URL now
//         },

//         {
//             evntTitle: "Quickstart Fullstack training program",
//             evntDtAndTime: "05-Jan-2024",
//             evntResourcePer: "Trainer",
//             evntTakeAway: "Hands on training - Free Internship",
//             evntForWhom: "Students, individuals",
//             evntFlyerLnk: eventFlyer2,
//             //evntFlyerLnk: "https://drive.google.com/uc?export=view&id=1hBPjornE1SMaQhr3s0pqWHNf_iUvjQdr"
//         },

//         {
//             evntTitle: "Headstart Fullstack training program",
//             evntDtAndTime: "05-Jan-2024",
//             evntResourcePer: "Trainer",
//             evntTakeAway: "Hands on training - Free Internship",
//             evntForWhom: "Students, individuals",
//             //evntFlyerLnk: "https://www.punemanagement.org.in/img/newphotos/new1.jpg"
//             evntFlyerLnk: eventFlyer3,
//         },

//         {
//             evntTitle: "Projects - From Chaos to Success",
//             evntDtAndTime: "12 Jan 2024 - 630pm",
//             evntResourcePer: "Mr Anirudhha Joshi",
//             evntTakeAway: "You will get the orientation on how to manage constraints and progress",
//             evntForWhom: "Business persons, students, individuals",
//             //evntFlyerLnk: "https://www.punemanagement.org.in/img/newphotos/ISOCertificate.jpg"
//             evntFlyerLnk: eventFlyer4,
//         },

//         {
//             evntTitle: "Blockchain and it's business applications",
//             evntDtAndTime: "19 Dec 2023 - 630pm",
//             evntResourcePer: "Mr Pankaj Mittal",
//             evntTakeAway: "You will get the orientation on how to manage constraints and progress",
//             evntForWhom: "Business persons, students, individuals",
//             //evntFlyerLnk: "https://www.punemanagement.org.in/img/newphotos/BestExporter.jpg"
//             evntFlyerLnk: eventFlyer5,
//         },

//         {
//             evntTitle: "Networking Meet - Mahatrainers",
//             evntDtAndTime: "20 Dec 2023 - 630pm",
//             evntResourcePer: "Mr Kaustubh Karwe",
//             evntTakeAway: "You will get the orientation on how to manage constraints and progress",
//             evntForWhom: "Business persons, students, individuals",
//             //evntFlyerLnk: "https://www.punemanagement.org.in/img/newphotos/BestExporter.jpg"
//             evntFlyerLnk: eventFlyer6,
//         },
//     ]



let upcomeEvntList = [];

function UpcomingEventsPg() {

    const secTitle = "";
    const [upcomingEventList, setUpcomingEventList] = useState([]);    
    const [loading, setLoading] = useState(true); // Track loading state

    ////////////////////////////////////
    // const fetchMulrec = async () => {

    //     console.log("inside multiple fetch");

    //     //const q = query(collection(db, "pmaEvntData"), where("age", ">", 20));
    //     const q = query(collection(db, "pmaEvntData"));

    //     const querySnapshot = await getDocs(q);
    //     querySnapshot.forEach((doc) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         //console.log(doc.id, " => ", doc.data().eventID);
    //         console.log(doc.id, " => ", doc.data());
    //         console.log(doc.data().eventTitle);
    //         console.log(doc.data().eventTkAway);

    //     });
    // }




    ////////////////////////////////////
    //let dataResults = [];
    

    useEffect(() => {
        let isMounted = true;
        //console.log("inside use effect");
        //####################

        const eventResults = async () => {
            // get the events data from database
    
            let dataResults = [];
       
          
            try{
    
            const q = query(collection(db, "pmaEvntData"));
    
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                /////////////
                //    console.log("#Logging data######");
                //    console.log(doc.data().eventID);
                //    console.log(doc.data().eventTitle);
                //   console.log(doc.data().eventTkAway);
                //   console.log(doc.data().eventSpeakrNm);
                //   console.log(doc.data().eventDt);
                //   console.log(doc.data().eventTm);
                //   console.log(doc.data().eventFlyerImgURL);
                //   console.log(doc.data().eventAdditionalDtls);
                //   console.log(doc.data().eventActive);
    
    
                /////////////////////
    
                //dataResults = [...dataResults, {id: doc.id, dRecord:doc.data()}];
                 dataResults = [...dataResults, doc.data()];
                //setUpcomingEventList([...dataResults]);
                //console.log("dataresult record >>>> ", dataResults);
                //return dataResults;
            });  // end of snapshot
    
            //upcomeEvntList.push(...dataResults);
            if (isMounted) {
                setUpcomingEventList([...dataResults]); // Update state only if component is still mounted
                setLoading(false); // Update loading state
            }
            //setUpcomingEventList([...dataResults]);
            upcomeEvntList = [...dataResults];
    
            
    
            //console.log("**** FINAL DATASET ****:", upcomingEventList);
            //console.log("#Logging data######");
    
            upcomeEvntList.map((ue) => {
               // console.log("Title is : ", ue.eventTitle);
               // console.log("ID is : ", ue.eventID);
            }
            )
            
            //console.log("final dataset length ; ", upcomeEvntList.length);
            // console.log(doc.data().eventTkAway);
            // console.log(doc.data().eventSpeakrNm);
            // console.log(doc.data().eventDt);
            // console.log(doc.data().eventTm);
            // console.log(doc.data().eventFlyerImgURL);
            // console.log(doc.data().eventAdditionalDtls);
            // console.log(doc.data().eventActive);
    
    
            // evntResultDataDocs.forEach((doc) => {
    
            //     //dataResults = [...dataResults, {id: doc.id, dRecord:doc.data()}]    
            //     console.log("#####", doc.id, " => ", doc.data());
            //     //console.log("--- dataset record--- :", dataResults.id, "=>", dataResults.dRecord);
            // });
    
            //console.log("**** FINAL DATASET ****:", dataResults);
            //upcomeEvntList = dataResults;
            //dataResults = [];
        } catch (error) {
            console.error('Error fetching data: ', error);
            setLoading(false); // Update loading state in case of an error
        }
        }

        //####################
        eventResults();
        //fetchMulrec();
        return () => {
            // Cleanup function: executed when the component unmounts
            isMounted = false; // Set the flag to false when unmounting
            //console.log("Mounting set to false ", isMounted)
        };
    }, [upcomingEventList]);

    if (loading) {
        return <p>Loading...</p>; // Show loading indicator if data is being fetched
    }

    return (

        <div>
            {/* <Button variant="filled" onClick={eventResults}>Sample Run</Button> */}
            <br />
            <PMALineBullet secTitle={secTitle + 'Upcoming Events'} />
            <br />
            <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                {

                    upcomingEventList.map((ue) => {
                        //console.log("@@@@ inside event list loop");
                        return (
                            <Grid2 xs={4}>
                                {/* {console.log("eventflyer link: ", ue.evntFlyerLnk)} */}
                                <PMAEventCard title={ue.eventTitle}
                                    eventDate={ue.eventDt} 
                                    eventTime={ue.eventTm} 
                                    rPer={ue.eventSpeakrNm}
                                    tkAway={ue.eventTkAway}
                                    forWho={ue.eventForWhom}
                                    flyerLnk={ue.eventFlyerImgURL}
                                    addnlDtls={ue.eventAdditionalDtls}
                                    evntID={ue.eventID} />
                            </Grid2>
                        )
                        }
                    )
                    
                }

            </Grid2>

            <PMAPageFooter />
        </div>
    );


}

//export { upcomeEvntList };  // this list will be used in stepper as well

export default UpcomingEventsPg;
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';


function HistoryAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}><b> History </b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>With a rich history that spans several decades, PMA has established itself as a leading platform for management
            professionals in Pune, India and beyond. Pune Management Association started way back in 1965 informally to
            promote the Excellence in Management profession. The official registration of Trust was done on 27 th May 1977
            under the able leadership of all time great Industrial leaders of our beloved country Late Padmabhushan SL
            Kirloskar, Late Mr VS Vaidya (Swastik Group), Late Mr CS Kirloskar (Kirloskar Group), Late Dr NA Kalyani
            (Kalyani Group) and Late Mr CV Joag (Bank of Maharashtra) and others.
            </p><p> PMA offers a wide range of opportunities to its members, including professional development opportunities,
            networking events, and access to industry experts and thought leaders The organization also hosts regular
            seminars, workshops, and conferences, providing members with valuable insights into the latest trends and best
            practices in management.
            </p><p>PMA is committed to fostering a community of like-minded professionals who are passionate about advancing their
            careers and contributing to the field of management. With a focus on collaboration, innovation, and continuous
            learning, PMA serves as a hub for professionals who are seeking to enhance their skills, expand their networks, and
            stay up to date with the latest developments in their field.
            </p><p>Whether you are a seasoned management professional or just starting out in your career, PMA offers a wealth of
            resources and opportunities to help you achieve your goals. By joining PMA, you will have access to a vibrant
            community of like-minded professionals who are committed to excellence in management and eager to share their
            knowledge and expertise with the attitude ‘<b>the joy of giving back to the society</b>’
            </p><p>More than 125 senior Members are actively working as volunteers thru 16 different CoEs to help PMA achieve goals.
            </p>        
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default HistoryAccordian;
import { Box } from "@mui/system";
import { Avatar, Divider, Paper, Stack, Typography } from "@mui/material";
import img1 from "../resources/FDImgs/img1.jpeg";
import img2 from "../resources/FDImgs/img2.PNG";

import PMAPageFooter from "../components/PMAPageFooter";

export default function FoundationDayPg() {

    return (
        <>

            <Box
                component="img"
                sx={{
                    // height: 233,
                    // width: 350,
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    maxWidth: '80%',
                    m: 5,
                    border:2
                }}
                alt="Foundation day"
                src={img1}
            />

            {/* <Paper variant="outlined" sx={{maxWidth:400}}>

                <img src={img1} />
            </Paper> */}

            <Box
                component="img"
                sx={{
                    // height: 233,
                    // width: 350,
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    maxWidth: '80%',
                    m: 5,
                    border:2,
                }}
                alt="Foundation day"
                src={img2}
            />

            <Divider></Divider>
            <PMAPageFooter />

        </>
    );
}
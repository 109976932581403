import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import registrationQR from "../resources/RegisterQRCode.png"
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { appAdminEmailID1, appAdminEmailID2, appAdminEmailID3, db } from '../firebase.config';
import { collection, getDocs, query, doc, deleteDoc, where } from "firebase/firestore";


export default function PMAEventCard(props) {

  const [isAdmin, setIsAdmin] = React.useState(false);

  let usrID = sessionStorage.getItem("uemail")



  // console.log("inside event card")
  // console.log(props.title);
  // console.log(props.flyerLnk);
  const handleDelete = async() =>{
    if (window.confirm("Are your sure to delete this record?"))
    { 
      //await deleteDoc(doc(db, "cities", "DC"));
      try{
    
        const q = query(collection(db, "pmaEvntData"), 
        where("eventID", "==", props.evntID));

        const rDocs = await getDocs(q);
        //const docRef = doc(db, "pmaEvntData"), rDocs[0].data().eventID);
        //await deleteDoc();
        rDocs.forEach((iDoc) => {
          const docRef = iDoc.id;
          // console.log("document to be deleted >>>", docRef);
          deleteEvnt(docRef);
          console.log("document deleted");
        })
      } catch(error) {
        console.log("Error during event delete operation", error);
      }
    }
  }

  const deleteEvnt = async(docRefID) => {
    await deleteDoc(doc(db, "pmaEvntData", docRefID));
  }

  React.useEffect(()=>{
    
    if ( usrID === appAdminEmailID1 ||
      usrID === appAdminEmailID2 ||
      usrID === appAdminEmailID3){
  
      setIsAdmin(true);
    }
  }, []);

  return (
    <Card sx={{ maxWidth: 350, m:2, border:1, borderColor:'primary.main'}}>
      <CardMedia
        sx={{ height: 200 }}
        image={props.flyerLnk}
        title="Online/Offline Session"
      />
      <CardContent sx={{m:2}}>
        <Typography gutterBottom variant="h6" component="div">
        <b>{props.title}</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <b>Date & Time: </b> {props.eventDate} at {props.eventTime}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <b>Resource Person: </b> {props.rPer}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <b>Takeaway: </b> {props.tkAway}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <b>For whom: </b> {props.forWho}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <b>Additional Details: </b> {props.addnlDtls}
        </Typography>
      </CardContent>
      <CardActions>
      <Box sx={{maxWidth:75, maxHeight:75, m:2}} component="img" src={registrationQR}/>
        <Button variant='contained' 
        href='https://forms.gle/2bH1JfRB4TrF6sro7'> Register </Button>
        {
          isAdmin 
            ? 
              <IconButton aria-label="delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            :
              <></>
        }
      </CardActions>
      {/* <CardMedia sx={{ml:2}} image={registrationQR} /> */}
      
    </Card>
  );
}
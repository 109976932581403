import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";


function PMAImpactNumbers(props) {

    return(
        <div>
            <Stack justifyContent="center" alignItems="center">
                {/* <Typography variant="h5" > <b>Years in Service</b></Typography> */}
                <Typography variant="h5" align = "center"> <b>{props.title}</b></Typography>
                <Box 
                sx={{ height: 100, width: 175, border: 5, mt:1, mb: 2, color:'primary.main'}}>

                    <Typography align = "center" 
                    variant="h4" 
                    sx={{color: 'primary.dark', pt:4 }}> <b>{props.iNumber}</b></Typography>

                </Box>
            </Stack>

            
        </div>
    );

}

export default PMAImpactNumbers;
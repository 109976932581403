import { Box, border, color } from "@mui/system";
import PMALineBullet from "../components/PMALineBullet";
import { useEffect, useState } from "react";
import { Alert, Button, Link, Snackbar, Stack, TextField, Typography } from "@mui/material";
import PMAPageFooter from "../components/PMAPageFooter";
import { collection, setDoc, addDoc, get, getDoc, doc, query, where, getDocs } from "firebase/firestore";
import { firebaseConfig, app, db } from "../firebase.config"


function ContactUsPg() {

    const [contactCard, setContactCard] = useState(
        {
            contactName: '',
            contactNo: 0,
            contactEmail: '',
            contactQueryDtls: '',

        }
    )

    // const [contactNm, setContactNm] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false)
    // const [snkOpen, setSnkOpen] = useState(false);
    const secTitle = '';

    let res = false;

    // const handleSnkClose = () => {
    //     setSnkOpen(false);
    // }

    const handleSubmit = (e) => {

        e.preventDefault()

        if ((contactCard.contactName.length <= 0) ||
            (contactCard.contactEmail.length <= 0) ||
            (contactCard.contactQueryDtls.length <= 0) ||
            (isNaN(contactCard.contactNo)) ||
            (contactCard.contactNo.length < 10)) {

            setFormSubmitted(false);
            alert("Please add valid inputs. All fields are mandatory to input");
            return;

        } else {

            res = addNewContactRec();
            //console.log("add record response is: ", res);
            //if (res) {
                setFormSubmitted(true);
                //console.log("form submitted value: ", formSubmitted);
                //resetFields();
                setContactCard({
                   contactName: '',
                    contactNo: 0,
                    contactEmail: '',
                    contactQueryDtls: '',

                });               

                // setSnkOpen(true);
                // console.log("SnkOPen status is: ", snkOpen);
                // <Snackbar
                //     open={snkOpen}
                //     autoHideDuration={5000}
                //     onClose={handleSnkClose}
                //     anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}>
                //     <Alert onClose={handleSnkClose} severity="success" sx={{ width: '100%' }}>
                //         Record added successfully!
                //     </Alert>
                // </Snackbar>

            //}
            //console.log("Inside else to add a new record: ", formSubmitted);

        }

        // console.log(contactCard)
        // console.log(formSubmitted)
    }

    // add a new record

    const addNewContactRec = async () => {
        //console.log("inside add new records");
        const contactUsdata = collection(db, "contactUsInfo");
        //console.log("DB value is: ", db);

        const resp = await setDoc(doc(contactUsdata, contactCard.contactNo), {
          contactName: contactCard.contactName,
          contactEmail: contactCard.contactEmail,
          contactNo: contactCard.contactNo,
          contactQueryDtls: contactCard.contactQueryDtls, 
          });

        console.log("record added to DB : ");
        //setFormSubmitted(true);
        //console.log("set formstate after record add", formSubmitted);
    }


    //   useEffect(() => {
    //     console.log("inside use effect");
    //       //fetchBlogs();
    //       addNewContactRec();
    //       //addNewRec();
    //   }, [])


    return (

        <div>
            <br />
            <PMALineBullet secTitle={secTitle + 'Contact Us'} />
            <br />
            <>
                <Box maxWidth={700} sx={{ border: 3, borderColor: 'primary.main', m: 5 }}>
                    {/* <Typography variant='h4' align='center' sx={{mt:5, color:'blue'}}> <b>Contact Us</b> </Typography> */}

                    <form onSubmit={handleSubmit}>
                        <Stack direction='column' spacing={2} sx={{ m: 5 }}>
                            <TextField
                                id="contact-nm" label="Contact Name*"
                                variant="outlined" 
                                onChange={ev => {
                                    setContactCard({
                                        ...contactCard,
                                        contactName: ev.target.value
                                    })
                                }} 
                                
                                // value={contactNm}

                                />

                            <TextField
                                id="contact-email" label="Contact Email ID*"
                                variant="outlined"
                                onChange={ev => {
                                    setContactCard({
                                        ...contactCard,
                                        contactEmail: ev.target.value
                                    })
                                }} />

                            <TextField
                                id="contact-mob" label="Contact Mobile*"
                                variant="outlined" onChange={ev => {
                                    setContactCard({
                                        ...contactCard,
                                        contactNo: ev.target.value
                                    })
                                }} />

                            <TextField
                                id="contact-qry" label="Your Query*"
                                variant="outlined" multiline={true} rows={3}
                                onChange={ev => {
                                    setContactCard({
                                        ...contactCard,
                                        contactQueryDtls: ev.target.value
                                    })
                                }}
                            />
                            <Typography variant="body" fontSize={12} color={'error'}>
                                * All fields are mandatory
                            </Typography>

                            <Button id="frmSub-button" type="submit"
                                variant="contained" disabled={formSubmitted}> Submit</Button>

                        </Stack>
                    </form>
                </Box>
                <br />
                <br />
                <PMALineBullet secTitle={secTitle + 'Address'} />
                <br />
                <Stack sx={{ m: 5 }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Box>
                        <Typography vairant="h6">
                            <b>Office : </b>
                        </Typography>
                        <Typography>
                            <p><b>Pune Management Association, </b></p>
                            <p>1332 Shivajinagar, 1st Floor, JM Road, </p>
                            <p>Institution of Engineers Building, </p>
                            <p> Adjacent to COEP Ground, Opp Modern Cafe, PUNE 411005</p>

                            {/* <p><a href="https://goo.gl/maps/bJx9WgoUBsmR8jhy9">(<b>Google Location</b>): </a> </p> */}
                            <p><b>Contact No : </b> 020 25533859, 7666262028 </p>
                            <p><b>Email Addresses:</b></p>
                            <ul>
                                <li>training@punemanagementassociation.org,</li>
                                <li>secretary@punemanagementassociation.org, </li>
                                <li>PuneManagementAssociation@gmail.com</li>
                            </ul>

                        </Typography>
                    </Box>
                    <Box>
                        <p><b>Google Map Location</b>:</p>
                        {/* <p><a href="https://goo.gl/maps/bJx9WgoUBsmR8jhy9"><b>Google Map Location</b>: </a> </p> */}

                        <p><a href="https://goo.gl/maps/bJx9WgoUBsmR8jhy9">

                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7566.062451853542!2d73.851547!3d18.527491!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c080cbf9d46b%3A0x995cf828e1e2a25d!2sPune%20Management%20Association!5e0!3m2!1sen!2sin!4v1701413574311!5m2!1sen!2sin"
                                width="350"
                                height="350"
                                style={{ border: "5" }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </a>
                        </p>

                    </Box>
                </Stack>
                <br />
                <PMAPageFooter />

            </>
            <br />
        </div>
    );

}

export default ContactUsPg;
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import CoETableComp from './CoETableComp';

//collaborations os events
function CoEAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> Collaborations of Events </b></Typography>

        </AccordionSummary>
        <AccordionDetails>
            <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>PMA organises various program with the help of various collaborating Organisations listed below -
              </p>        
          </Typography>
          <CoETableComp />
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default CoEAccordian;

import { Button } from "@mui/material";
import { auth, provider } from "../firebase.config";
import { signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PMAAddEventCard from "./PMAAddEventCard";
import PMAUserWelcomePg from "../PMA_Pages/PMAUserWelcomePg";

export default function GoogleSignInComp(){

    const [loginVal, setLoginVal] = useState("");
    const navigateTo = useNavigate(); // used for handling the routes

    const handleGLogIn = () => {
        try{
            signInWithPopup(auth, provider).then((data) => {
                setLoginVal(data.user.email);
                sessionStorage.setItem("uemail", data.user.email);
                //navigateTo('AddNewEvent');
            })
            console.log("Inside Login handle")
        } catch(error){
            sessionStorage.removeItem("uemail");
            console.log("Login Failed. Please try again ");
        }
    }

    useEffect(() => {
        const storedEmail = sessionStorage.getItem("uemail");

        if(storedEmail){
            setLoginVal(storedEmail);

        }
    }, []);

    return(
        <>
            {
                loginVal 
                ? 
                    <> <PMAUserWelcomePg userID={loginVal}/> </> 
                : 
                    <> 
                        <Button variant="contained" onClick={handleGLogIn}> 
                            Sign In with Google
                        </Button>
                    </>
            }

        </>
    );
}
import { Image } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase.config"

export default function CustomStepper(){

    const [eventData, setEventData] = useState([
        {
            eventFlyerImgURL:"No link available",
            eventTitle: "No title available",
            eventDt: new Date(0),
            eventSpeakrNm: "No speaker available",
            eventTkAway: "None",
            eventForWhom: "None",
            eventAdditionalDtls: "None",
        }
    ]);
    //let maxStep = 0;
    const [backBtnDisable, setBackBtnDisable] = useState(false);
    const [nextBtnDisable, setNextBtnDisable] = useState(false);
    const [activeStep, setActiveStep] = useState(0);  // as event data is initialized with blamk info this value is starting at 1
    const [maxStep, setMaxStep] = useState(0);
    const [loading, setLoading] = useState(true); // Track loading state


    function handleBack(){
        //console.log("inside Back = Active step is: ", activeStep);
        //console.log("inside Back = max  step is: ", maxStep);
        
        

        if(maxStep <= 0){
            setBackBtnDisable(true);
            return;
        }
        
        if(activeStep <= 0 ){
            setBackBtnDisable(true);
            if(maxStep > 0){
                setNextBtnDisable(false);
            }
            return;
        }
        
        setActiveStep(activeStep - 1);
        setBackBtnDisable(false);
        setNextBtnDisable(false);

        // console.log("Closing Back = Active step is: ", activeStep);
        // console.log("Closing Back = max  step is: ", maxStep);

    }

    function handleNext(){
        // console.log("inside NEXT = Active step is: ", activeStep);
        // console.log("inside Next = max  step is: ", maxStep);

        

        if(maxStep < 0 || activeStep == (maxStep - 1)){
            setNextBtnDisable(true);
            setBackBtnDisable(false);
            return;
        }
        
        // if(activeStep == (maxStep - 1)){
        //     setNextBtnDisable(true);
        //     return;
        // }

        setActiveStep(activeStep + 1);
        setNextBtnDisable(false);
        setBackBtnDisable(false);
        
        // console.log("Closing NEXT = Active step is: ", activeStep);
        // console.log("Closing Next = max  step is: ", maxStep);
    }



    useEffect(() => {

        let isMounted = true; // Flag to track component mount state

        const getEventData = async () => {
            try{
                const querySnapshot = await getDocs(query(collection(db, "pmaEvntData")));
                //console.log("Querysnapshot is ===>", querySnapshot);

                const dataArray = querySnapshot.docs.map((doc) => doc.data());

                //console.log("Mounting value is ", isMounted);

                if (isMounted) {
                    setEventData([...dataArray]); // Update state only if component is still mounted
                    setLoading(false); // Update loading state
                }
                //setEventData(dataArray); // for below setEventData - as original data copy is assigned default values will be overwritten and size of array will be same as number records received from database
                // console.log("Data array value is => ", dataArray);
                // console.log("Abs Event data is ===> ", eventData);
                sessionStorage.setItem('eventDataInSession', JSON.stringify(dataArray));
                const eventDataLen = dataArray.length;
                //setActiveStep(eventDataLen);
                setMaxStep(eventDataLen);
                //maxStep = eventDataLen;
                setActiveStep(maxStep-1);
                // console.log("Event data array length is: ", eventDataLen);
                // console.log("Number of events in queue ===> ", maxStep);
        } catch (error) {
            console.error('Error fetching data: ', error);
            setLoading(false); // Update loading state in case of an error
        }
    }

        // updateing stepper from database
        getEventData();

        return () => {
        // Cleanup function: executed when the component unmounts
        isMounted = false; // Set the flag to false when unmounting
        // console.log("Mounting set to false ", isMounted)
        };
            
    }, [maxStep]);


      // On component mount, check sessionStorage for previously fetched data
  useEffect(() => {
    const storedData = sessionStorage.getItem('eventDataInSession');

    //console.log("Stored data in session is =>", storedData);

  if (storedData) {
      setEventData(JSON.parse(storedData));
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Show loading indicator if data is being fetched
}

    return(
        <>
            <br/>
            <>
                {
                    eventData[activeStep] && (
                    <>
                        <Stack spacing={2} border={0.1}
                        alignItems='center'
                        justifyContent='center'
                        //marginLeft={5}
                        maxWidth='50%'
                        marginLeft={10}
                        >
                        <Box
                            component="img"
                            sx={{
                                height: 300,
                                //width: 'auto',
                                //maxHeight: { xs: 500, md: 200 },
                                //maxWidth: { xs: 350, md: 250 },
                                maxWidth: '80%',
                                //mt:2,
                                //p:5,
                                //pl:5,
                                pt:2,
                                //border: 0.1,
                                //borderBottom:0.1
                            }}
                            alt="Upcoming events"
                            src={eventData[activeStep].eventFlyerImgURL}
                            />
                        <Box alignItems='left' justifyContent='left' sx={{spacing:3, ml:3}}>
                            <Typography mb={1}> <b>Title: </b> {eventData[activeStep].eventTitle} </Typography>
                            <Typography mb={1}> <b>Date and Time: </b> &nbsp; {eventData[activeStep].eventDt}</Typography>
                            <Typography mb={1}> <b>Resource Person:</b>&nbsp;  {eventData[activeStep].eventSpeakrNm}</Typography>
                            <Typography mb={1}> <b>Take Away:</b>&nbsp; {eventData[activeStep].eventTkAway}</Typography>
                            <Typography mb={1}> <b>For Whom:</b>&nbsp;  {eventData[activeStep].eventForWhom}</Typography>
                            <Typography > <b>Additional Details:</b>&nbsp; {eventData[activeStep].eventAdditionalDtls}</Typography>
                        </Box>

                        <Box sx={{ bgcolor: 'primary.main', border: 1, width: '80%'}} />  {/* adding a line component */}
                        
                        <Stack
                            //direction="row" 
                            justifyContent="center" 
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                        >
                        

                        <Button onClick={handleBack} disabled={backBtnDisable}> <b> &lt;&lt;Back </b></Button>
                        <Button sx={{ml:10}} onClick={handleNext} disabled={nextBtnDisable}><b>Next&gt;&gt; </b></Button>

                        </Stack>
                        </Stack>    
                    </>
                    )
                }
            </>
            
        </>


    );

}
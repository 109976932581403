import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(no, name, designation) {
  return { no, name, designation };
}

const rows = [
    createData('1','Dr Bavadekar Pradeep','MITCON'),
    createData('2','Mr Bhat Raghavendra','Wiseman Advisory'),
    createData('3','Mr Chincholkar Anant','VP HR Kalyani'),
    createData('4','Dr Chitnis Ravikumar','Vice Chancellor MITWPU'),
    createData('5','Mr Dalvi Chandrakant','IAS'),
    createData('6','Dr Desai Girish','ED PCET'),
    createData('7','Mr Deshmukh Dilip','ex Charity Commissioner'),
    createData('8','Dr Deshpande Vivek','Director VIIT'),
    createData('9','Mr DholePatil Rahul','ex President Poona Club'),
    createData('10','Mr Erande Rajendra','ex Thermaex'),
    createData('11','Mr Gaikwad Hanumantrao','Chairman BVG'),
    createData('12','Mr Gaikwad Venkatrao','ex Secretary Irrigation'),
    createData('13','Dr Gavhane Sudhir','ex VC MGM Univ'),
    createData('14','Mr Gera Kumar','Builders Association'),
    createData('15','Air Marshal Gokhale Bhushan','Air Marshall'),
    createData('16','Mr Itkar Sachin','Director MEDC'),
    createData('17','Mr Jadhav Vitthalrao','Ex IGP'),
    createData('18','Mr Jena Rajiv','ex VP BAL'),
    createData('19','Dr Kanase Kailas','IPS, DG BARTI'),
    createData('20','Mr Khilari Girish','Founder CTO Elliot Systems'),
    createData('21','Mr Kopardekar Pradip','Kiara Lifespaces'),
    createData('22','Mr Lele Mandar','Regional Secy LUB'),
    createData('23','Mr Malhotra Mukesh','Weikfield'),
    createData('24','Mr Mankhedkar Yashwant','NYCA'),
    createData('25','Mr Mitra Rajiv','CEO Lactalis'),
    createData('26','Mr More Bhagwantrao','Human Rights Commision'),
    createData('27','Mr Pandey BP','ex IAS'),
    createData('28','Mr Pangarkar Shrikant','ex ED Bosch'),
    createData('29','Dr Patil Jaysingh','DY Patil Pratishthan'),
    createData('30','Mr Patil Sadanand','Commisioner Social Welfare'),
    createData('31','Pawar Padmashri Popatrao','Hivare Bazar'),
    createData('32','Mr Perepatil Bhaskar','Patoda'),
    createData('33','Mr RajeBhosale Meghraj','Chitrapat Mahamandal'),
    createData('34','Mr Shingnapurkar Ravindra','BoM SPPU'),
    createData('35','Dr Shirude GK','Vice Chancellor Sri Balaji'),
    createData('36','Mr Suravase Sadashiv','Jt Director Ind'),
    createData('37','Dr Utagikar Ravindra','VP Praj'),
    createData('38','Mr Valsangkar Anil','Social Work, NSDC'),
    createData('39','Mr Zagade Mahesh','ex Principal Secretary'),    
];

export default function ABTableComp() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >No</TableCell>
            <TableCell >Name</TableCell>
            <TableCell >Designation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.no}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.no}
              </TableCell>
              <TableCell >{row.name}</TableCell>
              <TableCell >{row.designation}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import hmImg1 from '../resources/homepageCarousal/hmImg1.jpg'
import hmImg2 from '../resources/homepageCarousal/hmImg2.jpg'
import hmImg3 from '../resources/homepageCarousal/hmImg3.jpg'
import hmImg4 from '../resources/homepageCarousal/hmImg4.jpg'
import hmImg5 from '../resources/homepageCarousal/hmImg5.jpg'
import hmImg6 from '../resources/homepageCarousal/hmImg6.jpg'
import hmImg7 from '../resources/homepageCarousal/hmImg7.jpg'
import { Box } from '@mui/system';
//import { Box, Container } from '@mui/system';

function PMACarousal(props)
{
       

    return (
        //<Box sx={{border: 2, bordercolor: 'primary.main', pl: 5, ml: 20, mr:20, pr: 5}}>
        <Carousel height  ={500} Animation='fade'>
                <img src={hmImg1} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
                <img src={hmImg2} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
                <img src={hmImg3} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
                <img src={hmImg4} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
                <img src={hmImg5} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
                <img src={hmImg6} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
                <img src={hmImg7} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
            
        </Carousel>
       // </Box>
    )
}

function Item(props)
{
    return (
        <Paper align='center'>
            {/* <h2>{props.item.name}</h2> */}
            <img 
                src= {props.item.imageSource}
                alt="PMA"
            />

            <p></p>

        </Paper>
    )
}

export default PMACarousal;
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import ABTableComp from './ABTableComp';

function ABAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> Advisory Board </b></Typography>

        </AccordionSummary>
        <AccordionDetails>
            <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>PMA Advisory Board is a structured and collaborative approach to engage, 
              encourage and enrich the PMA experiences for the stakeholder. 
              The purpose of the PMA advisory board is to support PMA activities to gain 
              new insights to explore new opportunities by stimulating robust, high-quality conversations. 
              The role of PMA advisory is to provide cutting-edge knowledge, critical thinking and analysis 
              to increase the confidence of PMA volunteers 
              and decision-makers who represent PMA through various Centres of Excellence.</p>
          </Typography>

          <ABTableComp />
          
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default ABAccordian;
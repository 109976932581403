import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, List, ListItem } from '@mui/material';

function CoExAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> Center Of Excellence (CoE) </b></Typography>

        </AccordionSummary>
        <AccordionDetails>
            <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>PMA extends its activities thru its various CoEs groups/sub-committees. The main objective of this is to provide platform to maximum number of active members. They work independently under PMA EC. PMA invites your participation in various committees mentioned below. Our endeavour would be to encourage participation of as many members as possible. PMA always been trying to establish better standards of management though such a social efforts. Both individual and institutional members  can participate in these sub-committees. Experts from various fields can form their own sub-Committees. Even Faculty Members or Students from the Institutes can form their own separate Committees.
              </p>
              <List>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Academics</b>  (Research, Students Engagement, Industry Relations,) </ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Business Excellence - Mr Vinay Kelkar</b> (Process Improvement, Project Management. I4.0) </ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Career Guidance - Dr. Vaibhav Deshmukh </b> (Career Counselling, Midcareer Counselling,) </ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Civic Affairs - Mr. Anil Shinde</b>(NGOs, Environment)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Corporate Relations - Mr. Rahul Joshi</b> (Outreach -Industries, MSMEs)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Finance</b> (Banking & Finance, Personal Finance, Taxation, Crypto-Currency)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Human Resources - Mr. Nitin Daangal</b> (Women in HR, T&D, Employee Retention, Personnel Management, IR & Legal)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Industry Academic Collaboration - Mr. Rahul Joshi</b> (Student related activities in Institutes)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Innovation and Emerging Technologies - Mr. Rahul Toshniwal</b> (AI-ML, RPA, Predictive Analysis, Innovation, Surveillance & Security, EVs, IoT, Block-Chain)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE MSME - Mr. Sanjay Kulkarni</b> (Entrepreneurship, Mentoring, MSME Scheme, Start-up Scheme)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Publications - Ms. Janet Anthony</b> (Publication of News letter & magazine)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Sales and Marketing</b> (Branding, Digital Marketing, Sales Strategy)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Skills Development - Mr. Dharmendra Patil</b> (Campus to Corporate, Image Branding,)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Start-up - Mr. Saket Vaidya</b> (Student Entrepreneurship, Coaching & Mentoring, MSME, Start-Ups, Women Entrepreneurship)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>CoE Sustainability - Mr. Bhagyesh Abhyankar</b> (ESG,Energy Conservation, Water Conservation)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>MahaTrainers - Dr. Smita Sovani</b> (Training & Development)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>MahaEngineers</b> (Technology Upgradation)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>Women In Management</b> (Women Empowerment)</ListItem>
               <ListItem sx={{ml:5,listStyleType: "disc", display: "list-item"}}><b>Young Managers - Mr. Shubham Pathak</b> (Youth Empowerment)</ListItem>
            </List>   
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default CoExAccordian;
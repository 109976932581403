import { useState } from "react";
import { storage } from "../firebase.config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Button, TextField, Stack } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

function FileUpload(props) {
    // State to store uploaded file
    const [myfile, setMyFile] = useState("");

    //let fileStoreageUrl="";

    // progress
    const [percentage, setPercentage] = useState(0);

    //visually hidden input
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    // Handle file upload event and update state
    function handleChange(event) {
        setMyFile(event.target.files[0]);
    }

    const handleUpload = () => {
        if (!myfile) {
            alert("Please upload the file first!");
        }

        const storageRef = ref(storage, `/files/${myfile.name}`);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, myfile);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prcnt = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercentage(prcnt);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    //console.log("URL is >>>>");
                    props.fileStoredAt(url);
                    //console.log(url);
                });
                //props.fileStoredAt(getFileStorageUrl);

            }
        );
    };

    return (
        <div>
            {/* <input type="file" onChange={handleChange} accept="/image/*" />
            <button onClick={handleUpload}>Upload Flyer</button> */}
            {/* <Button component="label" variant="contained" 
                startIcon={<CloudUploadIcon />}>
                 Upload file
                <VisuallyHiddenInput type="file" />
            </Button> */}
            <Stack spacing={3} margin={5}>
                <TextField type="file" onChange={handleChange} accept="/image/*" />
                <Button variant="contained" onClick={handleUpload}>Upload Flyer</Button>
                <br />
                <p>{percentage} "% done"</p>
            </Stack>
        </div>
    );
}

export default FileUpload;
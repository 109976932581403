import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, CardHeader, CardMedia } from '@mui/material';
import { lightBlue, red } from '@mui/material/colors';
import { Container, Stack, maxWidth } from '@mui/system';

// this function will show a document container Box with preview option
// it accepts incoming pros related to title and reference link of document

function BlogBox(props){

    return(
        <div>
        <Stack sx={{m:2, p:2, border:1, borderColor:'primary.main', 
                maxHeight:400, maxWidth:300, bgcolor: lightBlue[100]}}
                alignItems="center" justifyContent="center">
            
            <Typography variant='body' fontSize={14} marginBottom={1} > <b>{ props.title } </b> </Typography>
            
            <iframe src={props.blogLnk + "/preview"} 
            width={175} height={200}></iframe>
            
            <Button variant='contained' href={props.blogLnk + "/view"} 
            sx={{m:1}}> Learn More </Button>

        </Stack>
    </div>
  );
}

export default BlogBox;
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(organisation) {
  return { organisation };
}

const rows = [
        createData('Amhi Udyogini'),
        createData('AISSMS IOIT'),
        createData('BYST (Bhartiya Yuva Shakti Trust)'),
        createData('Divine HR Forum'),
        createData('Entrepreneurs International'),
        createData('GPS (Global Property Services)'),
        createData('HR Hope'),
        createData('IEEE (Institute of Electrical & Electronics Engineers)'),
        createData('IIIIE (Indian Institute of Industrial Engineers)'),
        createData('Laghu Udyog Bharati'),
        createData('MILE Training Foundation'),
        createData('PDPC (Poona District Productivity Committee)'),

];

export default function CoETableComp() {
  return (
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell ><b>Organisation</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.organisation}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.organisation}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
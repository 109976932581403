import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(organisation) {
  return { organisation };
}

const rows = [
    createData('AISSMS College of Engineering'),
    createData('AISSMS IOIT'),
    createData('Asian College of Science & Commerce'),
    createData('Aspire Knowledge & Skills – Skill Development , Smart City Incubation, NAPS'),
    createData('BakeKnowLogy'),
    createData('Bharat Vikas Parishad – Employment Exchange'),
    createData('BYST (Bharatiya Yuva Shakti Trust) – Entrepreneurship, Start u etc'),
    createData('Christ University'),
    createData('CIIL – Centre for Innovation, Incubation & Linkages (PCCOE)'),
    createData('Dr DY Patil Institute of Management Studies (DYPIMS)'),
    createData('DY Patil Institute of Master of Computer Applications & Management (DYPIMCAM)'),
    createData('DY Patil University Ambi'),
    createData('Global India Business Forum (GIBF)'),
    createData('Govt of Maharashtra, Skills Ministry'),
    createData('Hiraben Nanawati Institute of Management Research'),
    createData('IMED (Bharati Vidyapeeth)'),
    createData('IMERT Marathwada Mitra Mandal'),
    createData('Indira College of Engineering & Management'),
    createData('Indira Global Business School'),
    createData('lnnovera Schools'),
    createData('Institue of Industrial & Computer Management & Research (IICMR)'),
    createData('JSMPM\'s Bhivarabai Sawant Institute of Technology & Research'),
    createData('JSPM\'s Bhivrabai Sawant Polytechnic'),
    createData('JSPM\'s Charak College of Pharmacy & Research'),
    createData('JSPM\'s Imperial College of Engineering & Research'),
    createData('JSPM\'s Imperial College of Engineering & Research (MBA)'),
    createData('JSPM\'s Rajashri Shahu College of Engineering'),
    createData('Kautilya Institute of Management & Research'),
    createData('Kirloskar Institute of Management'),
    createData('MAPS Industries P Ltd'),
    createData('MILE Training Foundation (Real Estate & Construction)'),
    createData('MIT World Piece University'),
    createData('Modern College of Engineering - MBA Depertment'),
    createData('Poona Institute of Management Sciences & Entrepreneurship (PIMSE)'),
    createData('Pratibha College of Commerce & Computer Studies'),
    createData('Pune Business School'),
    createData('Ramseth Thakur College of Commerce & Science, Kharghar (RTCCS)'),
    createData('SB Patil Institute of Management'),
    createData('School of Inspirational Leadership'),
    createData('SkillEtz Foundation'),
    createData('Sri Balaji University'),
    createData('Tilak Maharashtra Vidyapeeth'),
    createData('Vishwakarma Institute of Information Technology'),
    createData('VITTI Research Foundation (AI, ML & DL)'),
];

export default function MoUTableComp() {
  return (
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell ><b>Organisation</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.organisation}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.organisation}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
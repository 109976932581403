import PMALineBullet from "../components/PMALineBullet";
import PMAPageFooter from "../components/PMAPageFooter";
import Test1ImgUpload from "../components/Test1ImgUpload";

//Image list related imports

import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useEffect } from "react";
import { Box } from "@mui/system";
import { Divider } from "@mui/material";

export default function MediaCenterPg(){
    
    const secTitle = "";
    return(
        <div> 
            
            <br/>
            <PMALineBullet secTitle={secTitle + 'Video Gallary'}/> 

            <Box sx={{m:2}}>
            For regular updates please visit and Subscribe to PMA Youtube channel at &nbsp;
            <a href="https://www.youtube.com/@PuneManagementAssociation" target="_blank" rel="noopener noreferrer">
            PMA Youtube channel </a>
            </Box>

          <Box sx={{m:3}}>
            <iframe width="770" height="433" 
            
            src="https://www.youtube.com/embed/oUzRdQVaaZg"
            title="PMA - Pune Management Association - 48th Foundation day" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
            </iframe>
            </Box>

            

            <br/>
          <Box sx={{m:3}}>
            <iframe width="770" height="433" 
            // src="https://www.youtube.com/embed/Ddq8FCL8-QE" 
            src="https://www.youtube.com/embed/PLhkOpmuoAg"
            title="PMA - Pune Management Association - 48th Foundation day" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
            </iframe>
            </Box>


            <br/>
            
            <PMAPageFooter/>
        </div>
    );
}

import * as React from 'react'
import {Typography, Button, Menu, MenuItem} from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function PMAMenu() {

    const navigateTo = useNavigate(); // used for handling the routes
    
    return (
        <div> 
            <nav>
            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}} 
            onClick={() => navigateTo('/')}>
                Home 
            </Button>

            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            onClick={() => navigateTo('upcomingEvents')}
            >Upcoming Events</Button>

            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            onClick={() => navigateTo('aboutus')}>About Us</Button>

            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            onClick={() => navigateTo('membership')}>Membership</Button>

            
            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            onClick={() => navigateTo('mediaCenter')}>Media Center</Button>

            {/* START Below code is commented as there are no current materials available */}
            {/* <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            onClick={() => navigateTo('Blog')}>Blog</Button> */}

            {/* END Above code is commented as there are current material available */}

            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            onClick={() => navigateTo('contactus')}>
            Contact Us
            </Button>

            
            <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'primary.main'}}
            // onClick={() => navigateTo('Articles')
            href='https://sites.google.com/view/pmacoe/articles' target='_blank'
            >
            News & Articles
            </Button>

            {/* code is temporarily added for 48th Foundation day */}
            {/* <Button variant="string" size= "large"  
            sx={{ textTransform: 'capitalize',  
            fontWeight: 'bold', fontSize: 18, color:'secondary.main'}}
            onClick={() => navigateTo('FD')}>
            Foundation Day
            </Button> */}

            </nav>
        </div>
    );
}


export default PMAMenu